<template>
  <DashboardAdminTemplate v-loading.fullscreen.lock="fullscreenLoading">
    <div class="pd-x-5 pd-b-3">
      <div>
        <el-row>
          <el-col :span="24" class="is-flex js-between ai-center">
            <div class="is-flex ai-center">
              <h2>ประวัติการเปลี่ยนแปลงความยินยอม</h2>
            </div>
            <!-- <el-dropdown trigger="click">
              <el-button type="primary" style="width: 130px">
                Export<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  ><div @click="exportData('all')">
                    Export ทั้งหมด
                  </div></el-dropdown-item
                >
                <el-dropdown-item
                  ><div @click="dialogExport = true">
                    จัดการตาราง Export
                  </div></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown> -->
          </el-col>
          <el-col :span="24" class="mg-y-5 bordered rounded pd-5">
            <el-form label-position="top" :model="form">
              <el-row class="">
                <el-col :span="24">
                  <el-row :gutter="20">
                    <el-col :span="24" :sm="8">
                      <el-form-item label="ค้นหา" class="padding-less">
                        <el-tooltip placement="bottom" :open-delay="800">
                          <div slot="content">
                            ค้นหาจาก ชื่อ หรือ นามสกุล<br />
                            หรือ อีเมล หรือ หมายเลขโทรศัพท์
                          </div>
                          <el-input
                            @input="filterData"
                            @clear="filterData()"
                            clearable
                            remove-tag="filterData()"
                            v-model="form.search"
                            placeholder="พิมพ์คำค้นหา"
                          ></el-input>
                        </el-tooltip>
                      </el-form-item>
                    </el-col>

                    <el-col :span="24" :md="6">
                      <el-form-item
                        label="สถานะข้อมูลส่วนบุคคล"
                        class="padding-less"
                      >
                        <el-select
                          v-model="form.status"
                          @change="filterData()"
                          @clear="(form.statusManage = []), filterData()"
                          @remove-tag="filterData()"
                          placeholder="สถานะข้อมูลส่วนบุคคล"
                          class="w-100"
                          popper-class="custom-select"
                          clearable
                          multiple
                          default-first-option
                        >
                          <el-option
                            v-for="item in infoOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                            <div class="is-flex ai-center">
                              <div class="circle-select"></div>
                              {{ item.label }}
                            </div>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="6">
                      <el-form-item
                        label="จัดการข้อมูลส่วนบุคคล"
                        class="padding-less"
                      >
                        <el-select
                          :disabled="checkdisable"
                          @change="filterData()"
                          @clear="filterData()"
                          @remove-tag="filterData()"
                          v-model="form.statusManage"
                          placeholder="สถานะจัดการข้อมูลส่วนบุคคล"
                          class="w-100"
                          popper-class="custom-select"
                          clearable
                          multiple
                          default-first-option
                        >
                          <el-option
                            v-for="item in manageOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                            <div class="is-flex ai-center">
                              <div class="circle-select"></div>
                              {{ item.label }}
                            </div>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
          <el-col :span="24" class="mg-y-6">
            <el-table
              border
              :data="tableData"
              style="width: calc(100% - 5px)"
              v-loading="loading"
            >
              <template slot="empty">
                <div class="noData">ไม่พบข้อมูลที่ค้นหา</div>
              </template>
              <el-table-column
                label="ชื่อ"
                prop="name"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                label="นามสกุล"
                prop="surname"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                label="หมายเลขโทรศัพท์"
                align="center"
                prop="mobilephone"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                label="อีเมล"
                align="center"
                header-align="center"
                prop="email"
              >
              </el-table-column>
              <el-table-column
                label="สถานะข้อมูลส่วนบุคคล"
                align="center"
                header-align="center"
              >
                <template slot-scope="scope">
                  <div
                    :class="
                      scope.row.consent == 4 ? 'text-success' : 'text-danger'
                    "
                  >
                    {{ getNameStatus(scope.row.consent) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="วันที่"
                align="center"
                header-align="center"
              >
                <template slot-scope="scope">
                  <div>
                    {{ moment(scope.row.createdAt).format("lll น.") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="จัดการข้อมูลส่วนบุคคล"
                align="center"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-checkbox
                    :false-label="0"
                    :true-label="1"
                    @change="updateStatus(scope.row.statusManage, scope.row.id)"
                    :disabled="scope.row.consent == 4"
                    v-model="scope.row.statusManage"
                  ></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="24">
            <div class="mg-y-3">
              <div class="block text-center">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-sizes="[15, 30, 50, 100, 500]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="dataTotal"
                >
                </el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-dialog class="font-32" :visible.sync="dialogExport">
        <h2 class="mg-t-0 text-center">จัดการข้อมูลส่วนบุคคล Export</h2>
        <div class="mg-y-5 pd-5 pd-b-6 bordered rounded">
          <el-form
            label-position="top"
            :rules="rules"
            ref="ruleForm"
            :model="customExport"
          >
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item
                  style="text-align: initial"
                  prop="date"
                  class="padding-less custom-form"
                >
                  <template slot="label" class="is-flex">
                    <span class="select is-required">
                      กรุณาเลือกวันที่เริ่มต้น - วันที่สิ้นสุด
                    </span>

                    <el-checkbox
                      v-model="customExport.dateAll"
                      @change="customExport.date = null"
                      >ทั้งหมด</el-checkbox
                    >
                  </template>
                  <el-date-picker
                    v-model="customExport.date"
                    type="daterange"
                    range-separator="ถึง"
                    start-placeholder="วันที่เริ่มต้น"
                    end-placeholder="วันที่สิ้นสุด"
                    class="w-100"
                    :default-time="['00:00:00', '23:59:59']"
                    :disabled="customExport.dateAll"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="สถานะข้อมูลส่วนบุคคล" class="padding-less">
                  <el-select
                    v-model="customExport.status"
                    placeholder="ทั้งหมด"
                    class="w-100"
                    popper-class="custom-select"
                    clearable
                    multiple
                    default-first-option
                  >
                    <el-option
                      v-for="item in infoOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                      <div class="is-flex ai-center">
                        <div class="circle-select"></div>
                        {{ item.label }}
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="จัดการข้อมูลส่วนบุคคล"
                  class="padding-less"
                >
                  <el-select
                    v-model="customExport.statusManage"
                    :disabled="checkdisableExport"
                    placeholder="ทั้งหมด"
                    class="w-100"
                    popper-class="custom-select"
                    clearable
                    multiple
                    default-first-option
                  >
                    <el-option
                      v-for="item in manageOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                      <div class="is-flex ai-center">
                        <div class="circle-select"></div>
                        {{ item.label }}
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="text-center">
            <el-button type="primary" @click="exportData()">Export</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </DashboardAdminTemplate>
</template>

<script>
import { HTTP } from "@/service/axios";
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import moment from "moment";
export default {
  components: {
    DashboardAdminTemplate
  },
  mounted() {
    this.fetchData(this.pageSize);
  },
  methods: {
    async funcExport(type) {
      this.fullscreenLoading = true;
      let customExport = JSON.parse(JSON.stringify(this.customExport));
      let obj = {
        start: "",
        end: "",
        status: [],
        statusManage: []
      };
      if (type != "all") {
        if (!customExport.dateAll) {
          obj.start = `${customExport.date[0]}`;
          obj.end = `${customExport.date[1]}`;
        } else {
          obj.start = "";
          obj.end = "";
        }
        obj.status = customExport.status;
        obj.statusManage = customExport.statusManage;
      }
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.post(`export/consent`, obj).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
        this.fullscreenLoading = false;
      });
      console.log(`export/consent  `, res);
      if (res.data.success) {
        var name = "_blank";
        var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];

        const url = process.env.VUE_APP_IMAGE_SYSTEM_URL + res.data.filename;
        //const win = window.open(url, specs);
        var createA = document.createElement("a");
        createA.setAttribute("href", url);
        createA.setAttribute("target", "_blank");
        createA.click();
        this.$message({
          type: "success",
          message: "ส่งออกข้อมูลสำเร็จ"
        });
        this.fullscreenLoading = false;
      }
    },
    async exportData(type) {
      try {
        if (type == "all" || this.customExport.dateAll) {
          this.funcExport(type);
        } else {
          this.$refs["ruleForm"].validate(async valid => {
            if (valid) {
              this.funcExport();
            }
          });
        }
      } catch {
        console.log("Export ERROR");
      }
    },
    async updateStatus(status, id) {
      let obj = {
        statusManage: status,
        id: id
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.put(`check/box/history/consent`, obj).catch(e => {
        console.log(`check/box/history/consent ERROR ${e}`);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success) {
        this.$message({
          message: "จัดการข้อมูลส่วนบุคคลสำเร็จ! ",
          type: "success"
        });
      }
    },
    getNameStatus(value) {
      let filter = this.infoOption.filter(a => a.value == value);
      if (filter.length > 0) {
        return filter[0].label;
      } else {
        return "";
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let offset = (this.currentPage - 1) * val;
      this.fetchData(offset);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let offset = (this.currentPage - 1) * this.pageSize;
      this.fetchData(offset);
    },
    filterData() {
      this.currentPage = 1;
      setTimeout(() => {
        this.fetchData(this.pageSize);
      }, 300);
    },
    async fetchData(val) {
      this.loading = true;
      try {
        let obj = {
          search: this.form.search,
          status: this.form.status,
          statusManage:
            this.form.statusManage.length > 1 ? [] : this.form.statusManage,
          limit: this.pageSize,
          offset: this.currentPage == 1 ? 0 : val
        };
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        let res = await HTTP.post(`search/consent`, obj).catch(e => {
          console.log(`search/consent ERROR ${e}`);
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
        if (res.data.success) {
          this.tableData = res.data.obj;
          this.dataTotal = res.data.total;
        }
        this.loading = false;
      } catch {
        console.log("fetchData Error");
        this.loading = false;
      }
    }
  },
  computed: {
    checkdisable() {
      let status = true;
      let index = this.form.status.filter(a => a != 4);
      if (index.length > 0) {
        status = false;
      }
      if (
        this.form.status.length == 0 ||
        (this.form.status.length == 1 && this.form.status[0] == 4)
      ) {
        this.form.statusManage = [];
      }
      return status;
    },
    checkdisableExport() {
      let status = true;
      let index = this.customExport.status.filter(a => a != 4);
      if (index.length > 0) {
        status = false;
      }
      if (
        this.customExport.status.length == 0 ||
        (this.customExport.status.length == 1 &&
          this.customExport.status[0] == 4)
      ) {
        this.customExport.statusManage = [];
      }
      return status;
    },
    infoOption() {
      return this.$store.state.infoOption;
    },
    manageOption() {
      return this.$store.state.manageOption;
    }
  },
  data() {
    var checkDated = (rule, value, callback) => {
      if (!this.customExport.dateAll) {
        if (this.customExport.date == null) {
          return callback(
            new Error("กรุณาเลือกวันที่เริ่มต้น - วันที่สิ้นสุด")
          );
        } else {
          return callback();
        }
      }
    };
    return {
      fullscreenLoading: false,
      currentPage: 1,
      dataTotal: 100,
      pageSize: 15,
      loading: true,
      rules: {
        date: [{ validator: checkDated, trigger: "change" }],
        infoStatus: [
          {
            required: true,
            type: "array",
            message: "กรุณาเลือกสถานะการใช้งาน",
            trigger: "change"
          }
        ],
        manageStatus: [
          {
            required: true,
            type: "array",
            message: "กรุณาเลือกสถานะการประเมิน",
            trigger: "change"
          }
        ]
      },
      dialogExport: false,
      tableData: [],
      form: {
        search: "",
        status: [],
        statusManage: [],
        limit: 10,
        offset: 0
      },
      customExport: {
        date: null,
        dateAll: false,
        status: [],
        statusManage: []
      }
    };
  }
};
</script>